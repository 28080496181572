import React, { useEffect, useRef, useState } from "react";
import ListItem from "./widget-common/widget-ListItem";
import ChevronRight from "../../assets/images/chevronRight.svg";
import infoRed from "../../assets/images/infoRed.png";
import { BottomSheet } from "./ModalPopUp";
import tasksDone from "../../assets/images/completed.png"
import { useIntl } from "react-intl";
import { UrlConstant } from "../../constants";
import { DataConstant } from "../../constants";
import { NotificationManager } from "react-notifications";
import { handleAuthFetchPost } from "../../../utils/apiCallsProvider";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";


const MobileView = ({ actionsList, onNavigate }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [listItemHeight, setListItemHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 
  const listItemRef = useRef(null);
  const intl = useIntl();

  useEffect(()=>{
    if (listItemRef.current) {
      const height = listItemRef.current.offsetHeight;
      setListItemHeight(height);
    }
  },[actionsList,screenWidth])

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tasksCompleted = actionsList === null;

  if (tasksCompleted) {
    return null;
  }

  return !isExpanded ? (
    <div className="action-items-container">
      {actionsList?.slice(0, 1).map((action, index) => (
        <div className="action-items-container-first" key={`MobileActionItem-Collapsed-${action?.userBookingId}-${index}`} ref={listItemRef} onClick={() => onNavigate(action?.userBookingId)}>
          <ListItem
            leadingIcon={<img src={infoRed} alt="info" />}
            heading={action.actionTitle}
            subtexts={[
              action.actionMessage,
              `${action.serviceName }, #${action.userBookingId}`,
            ]}
            trailingIcon={<img src={ChevronRight} alt="chevron" />}
            label={action.actionLabel}
          />
        </div>
      ))}

      {actionsList?.length > 1 && (
        <>
          <div className="action-items-container-second " style={{bottom:listItemHeight-20}} >
          
          </div>
          <div
            className="action-items-button"
            onClick={() => setIsExpanded(true)}
            style={{bottom: listItemHeight+25}}
            >
            +{actionsList?.length - 1}{intl.formatMessage({id: "more"})}
          </div>
        </>
      )}
    </div>
  ) : (
    <BottomSheet
      show={isExpanded}
      bottomSheetHeight={"80%"}
      title={`Action Items (${actionsList?.length})`}
      onClose={() => setIsExpanded(false)}
      subtitle={
        intl.formatMessage({id:"CompletedTasks"})
      }
      body={
        <div className="action-items-transition" style={{ width: "100%" }}>
          {actionsList?.map((action, index) => (
            <div key={`MobileActionItem-Expanded-${action?.userBookingId}-${index}`} onClick={() => onNavigate(action?.userBookingId)}>
              <ListItem
                leadingIcon={<img src={infoRed} alt="info" />}
                heading={action.actionTitle}
                subtexts={[
                  action.actionMessage,
                  `${action.serviceName}, #${action.userBookingId}`,
                ]}
                trailingIcon={<img src={ChevronRight} alt="chevron" />}
                label={action.actionLabel}
              />
              <div className="separator separator-solid"></div>
            </div>
          ))}
        </div>
      }
    />
  );
}

const DesktopView = ({ actionsList, onNavigate }) => {
  const intl = useIntl();
  const tasksCompleted = actionsList === null;

  return (
     !tasksCompleted ? <div style={{"width": "450px",paddingLeft:"20px",paddingRight:"30px", overflow: "auto"}}>
         
       <ListItem  heading={intl.formatMessage({id: "ActionItems"})} subtexts={[intl.formatMessage({id:"CompletedTasks"})]} />

       {actionsList?.map((action, index) => (
        <div key={`DesktopActionItem-${action?.userBookingId}-${index}`} style={{cursor: "pointer"}} onClick={() => onNavigate(action?.userBookingId)}>
        <ListItem
          leadingIcon={<img src={infoRed} alt="info" />}
          heading={action.actionTitle}
          subtexts={[
            action.actionMessage,
            `${action.serviceName}, #${action.userBookingId}`,
          ]}
          trailingIcon={<img src={ChevronRight} alt="chevron" />}
          label={action.actionLabel}
        />
        </div>
      ))}
          
        </div> : 
        <div style={{"width": "500px",paddingLeft:"20px",paddingRight:"80px",display:"flex",alignItems:"center",flexDirection:"column",height:"335px"}}>
          <ListItem  heading={intl.formatMessage({id: "ActionItems"})} subtexts={[intl.formatMessage({id:"CompletedTasks"})]} />
          <div style={{width:"350px",display:"flex",justifyContent:"center",alignItems:"center",height:"330px",flexDirection:"column",gap:"10px"}}>

            <img src = {tasksDone}/>
            <span style={{fontWeight:"bold",fontSize:"16px"}}>{intl.formatMessage({id:"AllSet"})}</span>
            <span style={{color:"#717171",fontWeight:"500",fontSize:"14px",textAlign:"center"}}>{intl.formatMessage({id: "TasksCompleted"})}</span>
          </div>
        </div>
  )
}

const ActionItems = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769); // Initial check
  const [actionsList, setActionsList] = useState([]);
  const history = useHistory();
  const widgetDetail = useSelector(state => state.widget);


  const goToBookingDetailPage = (userBookingId) => {
    history.push({
      pathname: `/widget/my-Appointments/psf-booking-detail/${userBookingId}`,
      state: { fromActionItems: true },
  });
};


  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 769);
      };

      window.addEventListener("resize", handleResize);

      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);


  useEffect(() => {
    handleAuthFetchPost(
          `${UrlConstant.common.getActionItems}`, JSON.stringify({})
        ).then((response) => {
          if (
            response &&
            response.status?.toLowerCase() ===
            DataConstant.apiResponseStatus.success.toLowerCase() &&
            response.data
          ) {
            const actionsList = response?.data?.entities;
            setActionsList(actionsList);
          } else {
            NotificationManager.error(response?.message);
          }
        }).catch((error) => {
          NotificationManager.error(error?.message);
        });

  }, [])

  if (!widgetDetail.isBusiness) {
    return null;
  }

 return(
  isMobile ? <MobileView actionsList={actionsList} onNavigate={goToBookingDetailPage} /> : <DesktopView actionsList={actionsList} onNavigate={goToBookingDetailPage} />
 )

};

export default ActionItems;
