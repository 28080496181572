import React from 'react'
import BusinessProfile2, { MobileBusinessProfile, WidgetNavigation } from './widget-businessProfile-2';
import styles from "./widgetLayout.module.css";
import { useSelector } from 'react-redux';
import BackButtonSvg from '../../assets/svg/BackButton';
import { IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import WidgetAlert from './widget-alert';
import ActionItems from './widget-actionItems';
const { REACT_APP_WHITELABELNAME_DISPLAYNAME } = process.env;
/**
 * WidgetLayout component.
 *
 * This component provides a consistent layout for widget pages, with a standard structure
 * for displaying the widget's main content, top navigation, actions, and a banner (optional).
 *
 * @param {React.ReactNode} children The main content of the widget page.
 * @param {React.ReactNode} actions The actions to be displayed at the bottom of the page.
 * @param {React.ReactNode} topNav The custom navigation to be displayed at the top of the page.
 * @param {boolean} [showHeaderNav=true] Whether to show the banner. Default is true, but this is an experimental feature and should not be turned off.
 * @param {object} history The navigation history object. This is required for navigating to other pages.
 * @param {object} leftActionButton Left action button.
 * @param {object} leftFull If left button should take full width in mobile.
 * @param {object} rightActionButton Right action button.ed for navigating 
 * @param {object} leftFull If right button should take full width in mobile.
 * @returns {JSX.Element} The rendered WidgetLayout component.
 *
 * @example
 * <WidgetLayout
 *  children={<WidgetContent />}
 *  actions={<WidgetActions />}
 *  topNav={<WidgetTopNav />}
 *  history={useHistory()}
 * />
 */
const WidgetLayout = ({children, actions, topNav, showHeaderNav=true, history, leftActionButton, leftFull, rightActionButton, rightFull, buttons, showBusinessInMobile, showAlert, showBusinessDetailsSection=false, noPaddingOnPageContainer, hideGlobalAlert, parentBusinessDetails, sideContent}) => {


  const widgetDetail = useSelector((state) => state.widget);
  return (
    <div className={styles.mainContainer}>
      {showHeaderNav && <div className={styles.bannerContainer}>
        <WidgetNavigation history={history} businessDetail={parentBusinessDetails || widgetDetail?.businessDetail} showBusinessInMobile={showBusinessInMobile}/>
      </div>}
      <div className={styles.mainContentContainer}>
        {showBusinessDetailsSection && <div className={styles.left}>
          <BusinessProfile2 widgetURL={widgetDetail?.widgetUrl} businessDetail={parentBusinessDetails || widgetDetail?.businessDetail} history={history} showBusinessInMobile={showBusinessInMobile} />
        </div>}
        <PageLayout
          topNav={topNav}
          actions={actions}
          leftActionButton={leftActionButton}
          leftFull={leftFull}
          rightActionButton={rightActionButton}
          rightFull={rightFull}
          buttons={buttons}
          noPaddingOnPageContainer={noPaddingOnPageContainer}
          showBusinessInMobile={showBusinessInMobile}
          showAlert={showAlert}
          hideGlobalAlert={hideGlobalAlert}
          parentBusinessDetails={parentBusinessDetails}
        >
          {children}
        </PageLayout>
        {sideContent && sideContent}
      </div>
    </div>
  )
}

export default WidgetLayout;

//#region Partial Widget Layout can be used in one place and PageLayout can be a seperate page with topNav, page and actions

export const PartialWidgetLayout = ({children, showHeaderNav=true, history, showBusinessInMobile, hideBusinessDetailsSection=false,showActionItems = false}) => {
  const widgetDetail = useSelector((state) => state.widget);
  return (
    <div className={styles.mainContainer}>
      {showHeaderNav && <div className={styles.bannerContainer}>
        <WidgetNavigation history={history} businessDetail={widgetDetail?.businessDetail} showBusinessInMobile={showBusinessInMobile}/>
      </div>}
      <div className={styles.mainContentContainer}>
        {!hideBusinessDetailsSection && <div className={styles.left}>
          <BusinessProfile2 widgetURL={widgetDetail?.widgetUrl} businessDetail={widgetDetail?.businessDetail} history={history} showBusinessInMobile={showBusinessInMobile}/>
        </div>}
        {children}
       { showActionItems ? <ActionItems/>  : null
}
      </div>
    </div>
  )
}

/**
 * PageLayout component is a wrapper for the page content. It renders the alert at the top and the page content and the actions at the bottom.
 * It also handles the visibility of the business details on mobile.
 * 
 * @prop {ReactNode} children - The content of the page.
 * @prop {ReactNode} topNav - The navigation menu at the top.
 * @prop {ReactNode} actions - The actions at the bottom.
 * @prop {ReactNode} leftActionButton - The action button at the left of the bottom.
 * @prop {boolean} leftFull - Whether the left action button takes the full width.
 * @prop {ReactNode} rightActionButton - The action button at the right of the bottom.
 * @prop {boolean} rightFull - Whether the right action button takes the full width.
 * @prop {ReactNode} buttons - The buttons at the bottom.
 * @prop {React.MutableRefObject<HTMLDivElement>} layoutPageContainerRef - The reference to the container of the page content.
 * @prop {boolean} noPaddingOnPageContainer - Whether to remove the padding of the page content container.
 * @prop {boolean} showBusinessInMobile - Whether to show the business details on mobile.
 * @prop {ReactNode} showAlert - The alert at the top of the page - if any it will show up on top.
 * @prop {boolean} hideGlobalAlert - Whether to hide the global alert for invalid url.
 */

export const PageLayout = ({children, topNav, actions, leftActionButton, leftFull, rightActionButton, rightFull, buttons, layoutPageContainerRef, noPaddingOnPageContainer, showBusinessInMobile, showAlert, hideGlobalAlert=false, parentBusinessDetails, className, isFullWidthComponent, isFullWidthContent}) => {
  const widgetDetail = useSelector((state) => state.widget);
  const widgetCode = widgetDetail?.widgetCode;
  const actionLoader = useSelector(state => state.loader.actionLoader);
  const intl = useIntl();
  return (
    <div className={`${styles.right} ${className}`}>
      {showAlert && showAlert}
      {!hideGlobalAlert && !widgetCode && !actionLoader ? 
        <WidgetAlert text={intl.formatMessage({id: "NoWidgetCodeError.Text"})} subText={intl.formatMessage({id: "NoWidgetCodeError.SubText"})} />
      : null}
      <div className={styles.rightInnerContainer}>
        <div className={styles.doubleContainer}> 
          <div style={{overflowY: "scroll", scrollBehavior: "smooth", display: "flex", flexDirection: "column", flexGrow: 1, boxSizing: "border-box", padding: noPaddingOnPageContainer ? "0" : "0 12px 0 20px"}}>
            <LayoutPageContainer ref={layoutPageContainerRef} isFullWidthContent={isFullWidthContent}>
              <MobileBusinessProfile showBusinessInMobile={showBusinessInMobile} businessDetail={parentBusinessDetails ||widgetDetail?.businessDetail} />
              <div className={styles.navigationContainer} style={{padding: !noPaddingOnPageContainer ? "0" : "0 20px", maxWidth: isFullWidthComponent ? '100%' : '896px'}}>
                {topNav}
              </div>
              {children}
            </LayoutPageContainer>
          </div>
          <LayoutActionContainer
            actions={actions}
            leftActionButton={leftActionButton}
            leftFull={leftFull}
            rightActionButton={rightActionButton}
            rightFull={rightFull}
            buttons={buttons}
          />
        </div>
      </div>
    </div>
  )
}

//#endregion 

//#region local components 
const LayoutPageContainer = React.forwardRef(({ children, isFullWidthContent }, ref) => {
  return (
    <div className={styles.topContainer} ref={ref} style={{ maxWidth: isFullWidthContent ? '100%' : '896px'}}>
      {children}
    </div>
  );
});

const LayoutActionContainer = ({actions, leftActionButton, leftFull, rightActionButton, rightFull, buttons}) => {
  return (
    actions ? <div className={`${styles.bottomContainer} ${buttons && "p-0 h-auto"}`}>
      <div className={`${styles.buttonContainer} ${buttons && "p-0 h-auto"}`}>
      {leftActionButton && (
        <div className={`${styles.buttonWrapper} ${leftFull ? styles.fullWidth : ''}`}>
          {leftActionButton}
        </div>
      )}
      {rightActionButton && (
        <>
          {!leftActionButton && <div className={styles.buttonWrapper}></div>}
          <div className={`${styles.buttonWrapper} ${rightFull ? styles.fullWidth : ''}`}>
            {rightActionButton}
          </div>
        </>
      )}
      {buttons}
      </div>
    </div> : <></>
  )
}
//#endregion

export const TopNav = ({onGoback, pageName="", subHeaderName="", inlineButtonWithTitle}) => {
  return (
    <div className="cm-flex-1 d-flex flex-column my-1 my-md-4">
      <div className={`d-flex ${inlineButtonWithTitle ? "" : "flex-column"}`}>
        {onGoback && <div><IconButton width={24} height={24} style={{marginLeft: "-14px"}}
          onClick={onGoback}
        >
          <BackButtonSvg />
        </IconButton></div>}
        {pageName && <div className="d-flex">
          <h5 className="my-1 font-700 font-size-20">{pageName}</h5>
        </div>}
      </div>
      {subHeaderName && <div className="d-flex">
          <p className="my-2 ">{subHeaderName}</p>
      </div>}
    </div>
  )
}

export const WidgetTopNav = ({children, onGoback, pageName="", headerName, subHeaderName=""}) => {
  return (
    <div className="d-flex flex-column cm-flex-1 w-100" >
      {headerName && <div className="d-flex align-items-center justify-content-start mt-4">
        <h3 className={`text-center text-header m-0 mt-3`}>
          {headerName}
        </h3>
      </div>}
      {(pageName || onGoback ) && <TopNav onGoback={onGoback} pageName={pageName} subHeaderName={subHeaderName} inlineButtonWithTitle={headerName}/>}
      {children}
    </div>
  )
}


export const RightActionButton = ({onClick, title, ...rest}) => {
  return (
    <div className={`text-right pr-0 rowl-md-6`}>
      <button
        {...rest}
        type="button"
        onClick={onClick}
        className={`btn cm-btn-color ${rest?.className}`}
      >
        {title}
      </button>
    </div>
  )
}

export const LeftDateInfoButton = ({topText, bottomText, ...rest}) => {
  return (
    <div className={`pl-0 row-md-6`}>
      <span className="font-weight-bold font-size-sm">
        {topText}
      </span>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="font-size-h2 font-weight-bolder">
          {bottomText}
        </h5>
      </div>
    </div>
  )
}


export const LeftActionButtonOutline = ({onClick, title, ...rest}) => {
  return (
    <div className="col-md-6 pl-0">
      <button
        {...rest}
        type="button"
        className={`btn cm-btn-outline-primary ${rest?.className}`}
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  )
}
